<template>
  <div v-if="shops">
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12" lg="12" class="text-black">
            <CRow>
              <CCol md="12" class="text-center">
                <h2 class="font-weight-normal text-dark">{{ $t('loyaltyPointMember') }}</h2>
                <h6 class="text-description">
                  {{ $t('loyaltyPointMemberDialog') }}
                </h6>
              </CCol>
            </CRow>
            <hr />
            <br />
            <div v-if="showTransaction === true">
              <table style="width:100%" aria-describedby="">
                <th scope="col"></th>
                <tr>
                  <td>
                    <input class="form-control" :placeholder="$t('searchMemberByName')" v-model="search" />
                  </td>
                  <td style="width:15%;padding:5px">
                    <CButton v-if="loadingButton === true" v-on:click="getMemberReport()" block color="success">
                      {{ $t('search') }}
                    </CButton>

                    <CButton v-else-if="loadingButton === false" block color="success" disabled>
                      <CSpinner color="white" size="sm" />{{ $t('loading') }}
                    </CButton>
                  </td>
                </tr>
              </table>

              <br />
              <CDataTable :items="details" :fields="fields" :loading="loading" hover border sorter>
                <template #name="{ item }">
                  <td class="py-2 font-weight-normal text-dark">
                    {{ item.name }}
                    <img alt="" v-if="item.isMember === 'line'" :src="imgSrc" width="20px" />
                  </td>
                </template>
              </CDataTable>
              <pagination :meta_data="meta_data" v-on:next="getMemberReport">
              </pagination>
            </div>
            <div v-else-if="showTransaction === false">
              <div class="text-center mt-3 text-dark">
                <h5>{{ $t('haveNotActivateCard') }}</h5>
                <p class="mt-3 text-dark">
                  {{ $t('haveNotActivateCardDialog') }}
                  <router-link :to="'/manual/reward-card'" class="underline">
                    {{ $t('systemUserManual') }}
                  </router-link>
                </p>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/service'
import crm from '@/services/crm'
import util from '@/util/util'
import Pagination from '@/containers/Pagination'
import NoShop from '@/containers/NoShop.vue'

export default {
  components: {
    Pagination,
    NoShop,
  },
  data() {
    return {
      data: [],
      details: [],
      search: '',
      loadingButton: true,
      loading: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      imgSrc: '../../../img/line-icon.png',
      showTransaction: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    fields() {
      return [
        { key: 'order', label: '#', _style: 'width:5%', sorter: false, _classes: 'text-dark font-weight-normal', },
        { key: 'name', label: this.$i18n.t('customerName'), sorter: false, _classes: 'text-dark font-weight-normal', },
        {
          key: 'netTotal',
          label: this.$i18n.t('paymentTotal'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
          sorter: false,
        },
        {
          key: 'point',
          label: this.$i18n.t('currentPoint'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
        },
        {
          key: 'redeemTotal',
          label: this.$i18n.t('redeemTime'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
        },
        {
          key: 'isUsedTrue',
          label: this.$i18n.t('used'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:10%',
        },
        /* {
          key: 'isUsedFalse',
          label: 'ยังไม่ใช้',
          _classes: 'text-right',
          _style: 'width:10%',
        }, */
      ]
    },
  },
  created() {
    this.getMemberReport()
    this.getRewardCardByUID()
  },
  methods: {
    ...util,
    async getItems(data) {
      let detail = []
      let countData = 0
      let netTotal = 0
      let isMember = ''
      let point = 0
      let start = this.meta_data.current_page * 50 - 50

      if (data.length !== 0) {
        countData = data.length
      }

      for (let i = 0; i < countData; i++) {
        if (data[i].netTotal === undefined) {
          netTotal = '0.00'
        } else {
          netTotal = util.convertCurrency(data[i].netTotal)
        }

        if (data[i].isMember === undefined) {
          isMember = ''
        } else {
          isMember = data[i].isMember
        }

        if (data[i].rewardPoint === undefined) {
          point = 0
        } else {
          point = util.convertNumber(data[i].rewardPoint)
        }

        let result = await this.getIsUsed(data[i].objectId)

        detail.push({
          objectId: data[i].objectId,
          order: start + i + 1,
          name: data[i].firstname + data[i].lastname || data[i].name,
          redeemTotal: result.totalRedeem,
          netTotal: netTotal,
          point: point,
          isUsedTrue: result.isUsedTrue,
          isUsedFalse: result.isUsedFalse,
          isMember: isMember,
        })

        this.details = detail
      }
    },
    getMemberReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = this.uid

      const params = {
        search: this.search,
        page: page,
        limit: 50,
      }

      axios
        .get('/shop/v1.0/getcustomer/' + uid, { params })
        .then((res) => {
          this.data = res.data.data
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.loading = false
          this.loadingButton = true

          this.getItems(this.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getIsUsed(memberObjectId) {
      const uid = this.uid

      let result = {}

      return crm
        .get(
          '/api/v1.0/' +
          uid +
          '/rewardcard/getisusedbymemberobjectId/' +
          memberObjectId
        )
        .then((response) => {
          let doc = response.data.data.documents

          let res = {
            totalRedeem: 0,
            isUsedTrue: 0,
            isUsedFalse: 0,
          }

          if (doc === null) {
            result = res
          } else {
            result = doc
          }

          return result
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getRewardCardByUID() {
      const uid = this.uid
      let documents = {}

      crm
        .get('/api/v1.0/' + uid + '/rewardcard/getbyuid')
        .then((response) => {
          documents = response.data.data.documents

          if (documents !== null) {
            if (documents.isSaved === 1) {
              this.showTransaction = true
            } else {
              this.showTransaction = false
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>
