<template>
  <CRow class="justify-content-center">
    <CCard color="white" text-color="dark" class="col-md-12" body-wrapper>
      <div v-if="documents !== null">
        <CRow>
          <CCol class="col-lg-6">
            <CJumbotron color="white" border-color="greyborder" class="p-3">
              <CCallout color="success">
                <h5 class="text-grey">{{ status }}</h5>
                <div class="row">
                  <strong class="col-md-10 col-sm-9 h4 font-weight-normal text-success">
                    {{ $t('status') }}
                  </strong>
                  <div class="col-md-2 col-sm-3 text-right text-success">
                    <em class="fas fa-star fa-2x"></em>
                  </div>
                </div>
              </CCallout>
            </CJumbotron>
            <CJumbotron color="white" border-color="greyborder" class="p-4">
              <p class="font-weight-normal">
                * {{ $t('exampleOfRewardCard') }}
              </p>
              <CAlert class="p-2" border-color="danger" color="danger" v-if="alertMessage">
                <span style="font-size:12px">
                  <i class="fas fa-exclamation-circle"></i>
                  {{ alertMessage }}
                </span>
              </CAlert>
              <CCarousel indicators animate>
                <CCarouselItem>
                  <CCard border-color="greyborder" style="border-radius:15px">
                    <CCardBody class="">
                      <h3 class="font-weight-normal text-center" v-if="logo !== ''">
                        <CImg :src="logo" width="20%" class="img-fluid" />
                        {{ shopName }}
                      </h3>
                      <div class="font-weight-normal text-center" v-else>
                        <CImg src="../../logo-ab.png" width="30%" />
                      </div>

                      <h6 class="font-weight-normal text-center">
                        {{ $t('accomulateComplete') }} {{ pointCard }} {{ unitName }} {{ $t('redeemFor') }}
                        {{ freeCard }}
                        {{ unitName }}
                      </h6>
                      <hr />

                      <div class="text-center">
                        <div v-if="rewardPoint < pointCard">
                          <span hidden>
                            {{ (calculate = pointCard - rewardPoint) }}
                          </span>
                          <p v-for="mypoint in pointCard - calculate" :key="mypoint" class="circle" :style="style">
                            {{ mypoint }}
                          </p>
                          <span hidden>
                            {{ (diff = pointCard - calculate) }}
                          </span>
                          <p v-for="allpoint in calculate" :key="allpoint + diff" class="circle">
                            {{ allpoint + diff }}
                          </p>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCarouselItem>
              </CCarousel>

              <hr />
              <p class="font-weight-normal">* {{ $t('noteCrm') }}</p>
              <p style="font-size:12px;" class="text-description">
                - {{ $t('noteCardDialog1') }}<br>
                - {{ $t('noteCardDialog2') }}<br>
                - {{ $t('noteCardDialog3') }}<br>
                - {{ $t('noteCardDialog4') }}<br>
                - {{ $t('noteCardDialog5') }}<br>
              </p>
            </CJumbotron>
          </CCol>
          <CCol class="col-lg-6">
            <CJumbotron color="white" border-color="greyborder" class="p-3">
              <CCallout color="orange">
                <h5 class="text-grey">{{ total }} {{ $t('item') }}</h5>
                <div class="row">
                  <strong class="col-md-10 col-sm-9 h4 font-weight-normal text-orange">
                    {{ $t('redeemRewards') }}
                  </strong>
                  <div class="col-md-2 col-sm-3 text-right text-orange">
                    <em class="fas fa-star fa-2x"></em>
                  </div>
                </div>
              </CCallout>
            </CJumbotron>
            <CJumbotron color="white" border-color="greyborder" class="p-3">
              <CCallout color="danger">
                <h5 class="text-grey">{{ isUsedTrue }} {{ $t('item') }}</h5>
                <div class="row">
                  <strong class="col-md-10 col-sm-9 h4 font-weight-normal text-danger">
                    {{ $t('used') }}
                  </strong>
                  <div class="col-md-2 col-sm-3 text-right text-danger">
                    <em class="fas fa-star fa-2x"></em>
                  </div>
                </div>
              </CCallout>
            </CJumbotron>
            <CJumbotron color="white" border-color="greyborder" class="p-3">
              <CCallout color="info">
                <h5 class="text-grey">{{ isUsedFalse }} {{ $t('item') }}</h5>
                <div class="row">
                  <strong class="col-md-10 col-sm-9 h4 font-weight-normal text-info">
                    {{ $t('availableCrm') }}
                  </strong>
                  <div class="col-md-2 col-sm-3 text-right text-info">
                    <em class="fas fa-star fa-2x"></em>
                  </div>
                </div>
              </CCallout>
            </CJumbotron>
            <CJumbotron color="white" border-color="greyborder" class="p-3">
              <CChartBar class="mt-4" :datasets="rewardChart.datasets" :labels="rewardChart.labels"
                :options="barOptions" />
            </CJumbotron>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <CJumbotron class="text-center" color="white" border-color="greyborder">
          <CImg src="../../../img/reward/reward-card.png" width="25%" class="img-fluid" />
          <p>
            {{ $t('noCard') }}
          </p>
          <p>{{ $t('createCardDialog') }} <b>{{ $t('cardSetting') }}</b></p>
        </CJumbotron>
      </div>
    </CCard>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import { CChartBar, CChartDoughnut, CChartPie } from '@coreui/vue-chartjs'
import crm from '@/services/crm'
import util from '@/util/util'
import { stat } from 'fs'

export default {
  components: {
    CChartDoughnut,
    CChartPie,
    CChartBar,
  },
  data() {
    return {
      data: [],
      allVoucher: 0,
      eVoucher: 0,
      preVoucher: 0,
      documents: '',
      pointCard: 0,
      freeCard: 0,
      unitName: '',
      redeemPoint: 0,
      alertMessage: null,
      rewardPoint: 5,
      rewardcardObjectId: '',
      total: 0,
      isUsedTrue: 0,
      isUsedFalse: 0,
    }
  },
  computed: {
    ...mapGetters(['shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shops[0]
    },
    shopName() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.shopName
      }
    },
    logo() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.remoteImagePath
      }
    },
    status() {
      if (this.documents) {
        if (this.documents.isSuspend === true) {
          return this.$i18n.t('disable')
        } else {
          if (this.documents.isSaved === 0) {
            return this.$i18n.t('draft')
          } else if (this.documents.isSaved === 1) {
            return this.$i18n.t('enable')
          }
        }
      }
    },
    style() {
      let stamp = ''
      if (this.logo !== '') {
        stamp = {
          backgroundImage: `url('${this.logo}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      } else {
        stamp = {
          backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0D3aSjZZJPWSoGAOz-Knv8xKupKxBd5eqdQ&usqp=CAU')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      }
      return stamp
    },
    rewardChart() {
      let labels = [this.$i18n.t('used'), this.$i18n.t('availableCrm')]

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: ['#FF7D63', '#5C7EAB'],
            data: [this.data.isUsedTrue, this.data.isUsedFalse],
            borderWidth: 1,
          },
        ],
      }
    },
    barOptions() {
      return {
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      }
    },
  },
  created() {
    this.getRewardCardByUID()
  },
  methods: {
    convertNum(num) {
      return util.convertNumber(num)
    },
    getDashboard() {
      const uid = this.uid

      crm
        .get(
          '/api/v1.0/' +
          uid +
          '/rewardcard/' +
          this.rewardcardObjectId +
          '/getdashboard'
        )
        .then((res) => {
          if (res.data.data.documents.length > 0) {
            this.data = res.data.data.documents[0]
            this.total = this.data.totalRedemption
            this.isUsedTrue = this.data.isUsedTrue
            this.isUsedFalse = this.data.isUsedFalse
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getRewardCardByUID() {
      const uid = this.uid

      crm
        .get('/api/v1.0/' + uid + '/rewardcard/getbyuid')
        .then((response) => {
          this.documents = response.data.data.documents

          if (this.documents !== null && this.documents !== undefined) {
            this.rewardcardObjectId = this.documents.objectId
            this.pointCard = this.documents.point
            this.freeCard = this.documents.free
            this.unitName = this.documents.unit.name
            this.redeemPoint = this.pointCard
            this.alertMessage = this.documents.alertMessage
            this.getDashboard()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>
<style>
.circle {
  background: #fff;
  width: 50px;
  height: 50px;
  margin-right: 5px;
  border-radius: 50%;
  border: 2px solid #666;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
