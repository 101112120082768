<template>
  <div v-if="shops" class="card tabs shadow-sm">
    <input
      type="radio"
      id="tab1"
      name="tab-control"
      :checked="dashbardActive === true"
    />
    <input
      type="radio"
      id="tab2"
      name="tab-control"
      :checked="settingActive === true"
    />
    <input
      type="radio"
      id="tab3"
      name="tab-control"
      :checked="memberActive === true"
    />

    <ul class="ulbar font-weight-normal">
      <li :title="$t('cardSetting')">
        <label for="tab1" role="button">
          <em class="fas fa-credit-card d-lg-none d-md-none"></em>
          <br /><span> {{ $t('dashboardMenu') }}</span>
        </label>
      </li>
      <li :title="$t('cardSetting')">
        <label for="tab2" role="button">
          <em class="fas fa-credit-card d-lg-none d-md-none"></em>
          <br /><span> {{ $t('cardSetting') }}</span>
        </label>
      </li>
      <li :title="$t('loyaltyPointMember')">
        <label for="tab3" role="button">
          <em class="fas fa-users d-lg-none d-md-none"></em>
          <br /><span> {{ $t('loyaltyPointMember') }}</span>
        </label>
      </li>
    </ul>
    <div class="slider"><div class="indicator"></div></div>

    <div class="content">
      <section>
        <dashboard></dashboard>
      </section>
      <section>
        <settings></settings>
      </section>
      <section>
        <transaction></transaction>
      </section>
    </div>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop'
import Settings from './Settings'
import Transaction from './Transaction'
import Dashboard from './Dashboard'
import '@/util/tabs.css'

export default {
  components: {
    Settings,
    Transaction,
    NoShop,
    Dashboard,
  },
  data() {
    return {
      dashbardActive: true,
      settingActive: false,
      memberActive: false,
    }
  },
  computed: {
    ...mapGetters(['shops']),
  },
}
</script>
<style scoped>
.tabs .slider {
  position: relative;
  width: 33%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
</style>
